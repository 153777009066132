import { useEffect, useState } from "react";
import { 
    Grid, 
    Column, 
    Select,
    SelectItem,
    Section,
} from '@carbon/react'

// api imports
import { getSuppliersApi } from '../utils/api/suppliers'

export const SupplierNavigation = ({ selectedSupplier, onChange=null }) => {    
    const [ suppliersList, setSuppliersList ] = useState(null)

    // query data as soon as we load
    useEffect(() => {
        async function fetchData() {
            const response = await getSuppliersApi()
            if (response?.length > 0) {
                setSuppliersList(response)
                //onChange?.(entity, getNavPath(treeData, entity), name)
            }
        }
        fetchData()
    }, [selectedSupplier])

    const handleChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        const name = ''
        if (value !== "")
            onChange?.(value, name)
        else
            onChange?.(null, '')
    }
    
    return (
        <Section style={{ paddingInline: 0 }}>
            <Select
                id="supplier"
                name="supplier"
                labelText="Fornecedor cadastrado:"
                onChange={handleChange}
                defaultValue={selectedSupplier}
            >
                <SelectItem value="" text="Todos" />
                {suppliersList?.map?.((e, idx) => (
                    <SelectItem selected={e.id === selectedSupplier} key={e.id} value={e.id} text={e.name} />
                ))}
            </Select>
        </Section>
    )
}
