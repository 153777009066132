import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { 
    Stack, 
    Heading, 
    Section, 
    Select,
    SelectItem
} from '@carbon/react';
import { useAuth } from '../contexts/AuthContext';
import { UiShell } from '../containers/Template'
import { DataCRUD } from '../containers/DataCRUD'
import { SupplierNavigation } from '../components/SupplierNavigation'

// api imports
import { getSuppliersApi, setUsersApi } from '../utils/api/accounts'

// DataManager form setup
const form = [
    [
        {
            label: 'Nome',
            helper: '',
            type: 'text',
            key: 'first_name'
        },
        {
            label: 'Sobrenome',
            helper: '',
            type: 'text',
            key: 'last_name'
        },
        {
            label: 'Status',
            helper: '',
            type: 'select',
            data: [
                {
                    name: 'Inativo',
                    id: false
                },
                {
                    name: 'Ativo',
                    id: true
                }
            ],
            default: true,
            optionText: 'name',
            key: 'is_active'
        }
    ],
    [
        {
            label: 'ID',
            helper: '',
            type: 'text',
            key: 'id',
            disabled: true
        },
        {
            label: 'Nivel de Acesso',
            helper: '',
            type: 'text',
            key: 'group_code',
            default: 'Fornecedores',
            disabled: true
        },
        {
            label: 'Email',
            helper: '',
            type: 'text',
            key: 'email'
        }
    ],
    [
        {
            label: 'Vínculo Funcional',
            helper: '',
            type: 'text',
            key: 'supplier_of',
            disabled: true
        },
        {
        
            label: 'Cadastrado em',
            helper: '',
            type: 'text',
            key: 'created_at',
            disabled: true
        },
        {
        
            label: 'Último Acesso em',
            helper: '',
            type: 'text',
            key: 'last_login',
            disabled: true
        }
    ]/* ,
    [
        {
            label: 'Senha',
            helper: '',
            type: 'password',
            key: 'password1'
        },
        {
            label: 'Confirmacao de Senha',
            helper: '',
            type: 'password',
            key: 'password2'
        },
        {}
    ] */
]

// DataGrid headers and data table formating setup
const dataGridHeader = [
    {
        header: 'Nome',
        key: 'name'
    },
    {
        header: 'Vínculo Funcional',
        key: 'supplier_of'
    },
    {
        header: 'Nível de Acesso',
        key: 'group_code'
    },
    {
        header: 'Acessos',
        key: 'access_count'
    },
    {
        header: 'Status',
        key: 'user_status'
    }
]

const dataGridSearchBy = ['first_name', 'last_name', 'supplier_of', 'group', 'access_count', 'user_status'] //dataGridHeader.map(item => item.key.endsWith('_code') ? item.key.slice(0, -5) : item.key)

const SuplierOperators = () => {
    const [ selectedSupplier, setSelectedSupplier ] = useState(null)
    const [ reloadDataCRUD, setReloadDataCRUD ] = useState(0);
    const { user } = useAuth();
    const userSupplierLink = user.supplier_link
    const canAdd = user.permissions.includes('add_supplier_user') && (selectedSupplier || userSupplierLink)
    const canChange = user.permissions.includes('change_supplier_user')

    // DataCRUD callbacks
    const onSaveData = (result) => {
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data saved: ${result}`)
        }
    }

    const onCreateData = (result) => {
        // update object data?
        if (result?.error) {
            console.log(`Error: ${result}`)
        } else {
            console.log(`Data created: ${result}`)
        }
    }

    const onNavChange = (supplierId) => {
        setSelectedSupplier(supplierId)
    }
    
    return (
        <UiShell>
            <Stack gap={8}>
                <Section>
                    <Heading className="primary">Representantes Autorizados</Heading>
                    <p>Usuários autorizados a receber e responder pedidos de cotação, acompanhar demandas e logística de envios e remessas.</p>
                </Section>
                {!userSupplierLink &&
                    <Section>
                        <SupplierNavigation 
                            selectedSupplier={selectedSupplier}
                            onChange={onNavChange}
                        />
                    </Section>
                }
                <Section>
                    <DataCRUD 
                        title={'Representantes Autorizados'} 
                        desc={'Todos os cadastros'} 
                        headers={dataGridHeader} 
                        searchBy={dataGridSearchBy}
                        getApi={getSuppliersApi}
                        getApiParam={{ supplierId: userSupplierLink ?? selectedSupplier }}

                        canAdd={canAdd}
                        canChange={canChange}
                        form={form}
                        formTitleKey={'first_name'}
                        setApi={setUsersApi}
                        setApiParam={{ supplier_link: userSupplierLink ?? selectedSupplier }}
                        onSave={onSaveData}
                        onCreate={onCreateData}
                        forceReload={reloadDataCRUD}
                    /> 
                </Section>
            </Stack>
        </UiShell>
    )
}

export default SuplierOperators